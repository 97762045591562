import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import LeftArrow from "../assets/images/home/left_arrow.png";
import RightArrow from "../assets/images/home/right_arrow.png";
import LocationLogo from "../assets/images/home/location_logo.png";
import Contact from "../assets/images/home/contact.png";

const ButtonGroup = ({ next, previous, goToSlide, ...rest }) => {
  const {
    carouselState: { currentSlide },
  } = rest;
  return (
    <div className="flex w-full justify-center lg:justify-start items-center gap-8 h-24">
      <button
        onClick={() => previous()}
        className="z-50 bg-transparent border-green rounded-xl border-2 py-3 px-4"
      >
        <img src={LeftArrow} alt="Left Arrow" className="w-2 object-contain" />
      </button>

      <button
        onClick={() => next()}
        className="z-50 bg-transparent border-green rounded-xl border-2 p-3 px-4"
      >
        <img src={RightArrow} alt="Left Arrow" className="w-2 object-contain" />
      </button>
    </div>
  );
};

const VerticalsCarousel = (props) => {
  const { data } = props;
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };

  return (
    <div className="relative flex flex-col w-full lg:w-1/2 h-full flex-1">
      <Carousel
        className="relative py-4 lg:py-12"
        additionalTransfrom={0}
        arrows={false}
        autoPlaySpeed={3000}
        centerMode={false}
        containerClass="flex "
        dotListClass=""
        draggable
        focusOnSelect={false}
        infinite
        itemClass=""
        keyBoardControl
        minimumTouchDrag={80}
        pauseOnHover
        renderArrowsWhenDisabled={false}
        renderButtonGroupOutside={true}
        renderDotsOutside={false}
        rewind={false}
        rewindWithAnimation={false}
        rtl={false}
        shouldResetAutoplay
        showDots={false}
        sliderClass=""
        slidesToSlide={1}
        swipeable
        responsive={responsive}
        customButtonGroup={<ButtonGroup />}
      >
        {data.map((item, id) => {
          return (
            <div
              className="flex flex-col gap-2 lg:max-w-xl justify-center lg:justify-start items-center lg:items-start"
              key={id}
            >
              <h2 className="font-title text-yellow text-4xl">
                {item.branch_name}
              </h2>
              {/* <h3 className="font-title text-green text-4xl">2016</h3> */}
              <p className="font-description-medium text-lg text-center lg:text-left">
                {item.address_list && item.address_list.length > 0
                  ? [
                      item.address_list[0].address_line_1,
                      item.address_list[0].address_line_2,
                      item.address_list[0].address_line_3,
                      item.address_list[0].pincode,
                      item.address_list[0].city,
                      item.address_list[0].state_name,
                      item.address_list[0].country_name0,
                    ]
                      .filter((line) => line) // Filter out null or empty values
                      .join(", ") // Join the lines with a comma
                  : "No address provided"}
              </p>
              <div className="flex gap-4 flex-wrap justify-center">
                <a
                  href={item.branch_location_link}
                  target="_blank"
                  className="flex gap-2 py-2 px-6 bg-green rounded-xl items-center justify-center"
                >
                  <span className="uppercase text-white font-description">
                    Locate Us
                  </span>
                  <img
                    src={LocationLogo}
                    alt="Locate Us"
                    className="object-contain w-6"
                  />
                </a>
                {/* <button className="flex gap-2 py-2 px-6 bg-green rounded-xl items-center justify-center">
                  <img
                    src={Contact}
                    alt="Locate Us"
                    className="object-contain w-4"
                  />
                  <span className="uppercase text-white font-description">
                    +91 9898 989898
                  </span>
                </button> */}
              </div>
            </div>
          );
        })}
      </Carousel>
    </div>
  );
};

export default VerticalsCarousel;
