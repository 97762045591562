import React, { useEffect, useMemo } from "react";
import HomeMidBG from "../assets/images/home/back_bg_mid.png";
import MenuBanner from "../assets/images/home/banner_1.png";
import MenuBannerMobile from "../assets/images/home/banner_mobile.png";
import HomeMidBGMobile from "../assets/images/home/back_mid_mobile.png";
import Swiggy from "../assets/images/home/swiggy.png";
import Zomato from "../assets/images/home/zomato.png";
import useRestaurantCategory from "../data/talons/RestaurantCategory/useRestaurantCategory";
import {
  getBranchId,
  getBranchMasterList,
  getRestaurantCategoryList,
  getRestaurantProductList,
} from "../data/selectors/restaurantCategory.selector";
import { useSelector } from "react-redux";
import { find, size } from "lodash";
import Dropdown from "../components/Dropdown";

const Menu = () => {
  const RestaurantCategory = useSelector(getRestaurantCategoryList);
  const restaurantProduct = useSelector(getRestaurantProductList);
  const branchMasterList = useSelector(getBranchMasterList);

  const branchId = useSelector(getBranchId);

  const {
    handleBranchMasterComboChange,
    handleRestaurantCategoryNameChange,
    fetchBranchMasterList,
    categoryId,
    menuURL,
    setCategoryId,
    fetchRestaurantCategoryList,
    fetchRestaurantProductList,
  } = useRestaurantCategory();

  const branchData = find(branchMasterList, ["id", branchId]);

  // useEffect(() => {
  //   if (branchId) {
  //     fetchBranchMasterList().then(() => {
  //       fetchRestaurantCategoryList({ branchId: branchId }).then(() => {
  //         fetchRestaurantProductList({
  //           categoryId: categoryId,
  //           branchId: branchId,
  //         });
  //       });
  //     });
  //   }
  // }, [branchId]);

  useEffect(() => {
    fetchBranchMasterList();
  }, [fetchBranchMasterList]);

  useEffect(() => {
    if (branchId) {
      fetchRestaurantCategoryList({ branchId });
    }
  }, [branchId, fetchRestaurantCategoryList]);

  useEffect(() => {
    if (categoryId && branchId) {
      fetchRestaurantProductList({ categoryId, branchId });
    }
  }, [categoryId, branchId, fetchRestaurantProductList]);

  const renderRestaurantProduct = useMemo(() => {
    if (!size(restaurantProduct)) return null;

    return restaurantProduct.map((item, id) =>
      id < 10 ? (
        <div className="flex justify-between gap-8" key={id}>
          <div className="flex flex-col gap-2">
            <p className="text-green font-description-bold text-lg lg:text-2xl">
              {item.name}
            </p>
            <p className="text-green font-description text-sm md:text-lg line-clamp-3">
              {item.description}
            </p>
          </div>
          {/* <p className="text-green font-description-bold text-lg lg:text-2xl">
            {item.mrp}
          </p> */}
        </div>
      ) : (
        <></>
      )
    );
  }, [restaurantProduct]);

  return (
    <section className="relative flex w-full h-full px-4 md:px-8 lg:px-20 xl:px-36 pb-16 pt-6 md:pt-24 lg:pt-32 xl:pt-36 min-h-screen">
      <img
        src={HomeMidBG}
        alt="Background Image"
        className="absolute w-full h-full top-0 left-0 -z-10 hidden xl:flex"
      />
      <img
        src={HomeMidBGMobile}
        alt="Background Image"
        className="absolute w-full h-full top-0 left-0 -z-10 flex xl:hidden"
      />
      <div className="flex w-full h-full flex-col">
        <div className="hidden md:flex gap-4 lg:gap-12 bg-green rounded-2xl py-4 lg:py-6 px-8 lg:px-20 items-center justify-center">
          <p className="font-title text-yellow text-3xl lg:text-4xl tracking-wider">
            Menu
          </p>
          <Dropdown
            data={branchMasterList}
            value={branchId}
            title="Branch"
            handleDropdownChange={handleBranchMasterComboChange}
            classname={
              "bg-yellow py-2 px-2 lg:px-8 rounded-xl text-green font-description-bold text-2xl w-full"
            }
          />
          <Dropdown
            data={RestaurantCategory}
            value={categoryId}
            title="Category"
            handleDropdownChange={handleRestaurantCategoryNameChange}
            classname={
              "bg-yellow py-2 px-2 lg:px-8 rounded-xl text-green font-description-bold text-2xl w-full"
            }
          />
        </div>
        <div className="flex flex-col md:hidden items-center justify-center">
          <p className="font-title text-yellow text-3xl tracking-wider bg-green rounded-t-xl py-2 px-4 -mb-1">
            Menu
          </p>
          <div className="bg-green rounded-xl py-2 px-4 w-full">
            <Dropdown
              data={branchMasterList}
              value={branchId}
              title="Branch"
              handleDropdownChange={handleBranchMasterComboChange}
              classname={
                "bg-yellow py-2 px-2 rounded-lg text-green font-description-bold text-lg w-full"
              }
            />
          </div>
          <div className="bg-green rounded-b-xl py-2 px-4 w-3/4 -mt-1">
            <Dropdown
              data={RestaurantCategory}
              value={categoryId}
              title="Category"
              handleDropdownChange={handleRestaurantCategoryNameChange}
              classname={
                "bg-yellow py-2 px-2 rounded-lg text-green font-description-bold text-lg w-full"
              }
            />
          </div>
        </div>
        <div className="flex flex-col-reverse xl:flex-row gap-12 py-8 md:py-12">
          <img
            src={MenuBanner}
            alt="Menu Image"
            className="w-2/5 object-contain hidden xl:flex"
          />
          <img
            src={MenuBannerMobile}
            alt="Menu Image"
            className="w-full object-contain flex xl:hidden"
          />
          <div
            className={`grid ${
              restaurantProduct.length > 5
                ? "grid-cols-2 gap-4 md:gap-12"
                : "grid-cols-1 gap-0"
            }  w-full`}
          >
            {renderRestaurantProduct}
          </div>
        </div>
        <div className="flex flex-wrap gap-2 md:gap-8 lg:gap-12 pb-12 justify-center items-center">
          <p className="font-description-bolditalic text-green text-2xl md:text-3xl text-center">
            ALSO AVAILABLE ON
          </p>
          <div className="flex flex-wrap gap-2 md:gap-8 lg:gap-12 justify-center items-center">
            <a href={branchData?.swiggy_link} target="_blank">
              <img src={Swiggy} alt="Swiggy" className="object-contain h-12" />
            </a>
            <a href={branchData?.zomato_link} target="_blank">
              <img src={Zomato} alt="Zomato" className="object-contain h-12" />
            </a>
            <button
              className="flex bg-yellow rounded-full border-2 border-green px-4 md:px-8 py-2"
              hidden={size(menuURL) > 0 ? false : true}
            >
              <a
                className="text-green text-lg md:text-xl font-description-semibold"
                type="media_type"
                href={menuURL}
                download="menu"
                target="_blank"
                rel="noopener"
              >
                Download Menu
              </a>
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Menu;
