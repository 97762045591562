import React, { useState, useEffect } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import LeftArrow from "../assets/images/home/left_arrow.png";
import RightArrow from "../assets/images/home/right_arrow.png";
import LocationLogo from "../assets/images/home/location_logo.png";

const ButtonGroup = ({ next, previous }) => {
  return (
    <div className="flex w-full justify-center items-center gap-8 h-24">
      <button
        onClick={previous}
        className="z-50 bg-transparent border-green rounded-xl border-2 py-3 px-4"
      >
        <img src={LeftArrow} alt="Left Arrow" className="w-2 object-contain" />
      </button>
      <button
        onClick={next}
        className="z-50 bg-transparent border-green rounded-xl border-2 py-3 px-4"
      >
        <img src={RightArrow} alt="Right Arrow" className="w-2 object-contain" />
      </button>
    </div>
  );
};

const VerticalsCarouselOffer = ({ data, onSlideChange }) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [currentStatus, setCurrentStatus] = useState("Coming Soon...");
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    const filtered = data.filter((item) => {
      if (!item.from_date || !item.upto_date) return false;
      const currentDate = new Date();
      const fromDate = new Date(item.from_date);
      const uptoDate = new Date(item.upto_date);
      return currentDate >= fromDate && currentDate <= uptoDate;
    });
    setFilteredData(filtered.length > 0 ? filtered : data);
  }, [data]);

  useEffect(() => {
    if (filteredData.length > 0 && filteredData[currentSlide]) {
      setCurrentStatus(filteredData[currentSlide].status_val || "Coming Soon...");
    }
  }, [currentSlide, filteredData]);

  const responsive = {
    desktop: { breakpoint: { max: 3000, min: 1024 }, items: 1, slidesToSlide: 1 },
    tablet: { breakpoint: { max: 1024, min: 464 }, items: 1, slidesToSlide: 1 },
    mobile: { breakpoint: { max: 464, min: 0 }, items: 1, slidesToSlide: 1 },
  };

  return (
    <div className="relative flex flex-col w-full lg:w-1/2 h-full flex-1">
      <Carousel
        additionalTransform={0}
        arrows={false}
        autoPlaySpeed={3000}
        centerMode={false}
        className="relative py-4 lg:py-1"
        containerClass="flex"
        draggable
        focusOnSelect={false}
        infinite
        keyBoardControl
        minimumTouchDrag={80}
        pauseOnHover
        renderButtonGroupOutside={true}
        responsive={responsive}
        customButtonGroup={<ButtonGroup />}
        swipeable
        afterChange={(previousSlide, { currentSlide }) => {
          setCurrentSlide(currentSlide);
          onSlideChange(currentSlide);
        }}
      >
        {filteredData.map((item, index) => (
          <div key={index} className="text-center px-4">
            <h1 className="text-green font-extrabold text-2xl lg:text-5xl mb-4">
              We are {item.status_val || "Coming Soon..."}
            </h1>
            <p className="text-green-700 font-normal text-lg mb-4">
              Currently, we are at 12 locations, with plans to expand to more
              key locations across Gujarat and India.
            </p>
            <div className="flex flex-col lg:flex-row gap-4 lg:gap-6 items-center justify-center">
              <img
                src={item.image_url || "default_image_path_if_needed"}
                alt={`Carousel Image for ${item.branch_name}`}
                className="w-full max-w-xs lg:max-w-sm object-cover rounded-lg mx-auto"
              />
              <div className="text-center lg:w-1/2">
                <h2 className="font-title text-yellow text-2xl lg:text-3xl mb-2">
                  {item.branch_name}
                </h2>
                <p className="font-description-medium text-sm lg:text-lg">
                  {item.address}
                </p>
                <a
                  href={item.branch_location_link}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex gap-2 mt-4 py-2 px-4 bg-green rounded-xl items-center justify-center text-white font-description"
                >
                  Locate Us
                  <img src={LocationLogo} alt="Locate Us" className="w-6" />
                </a>
              </div>
            </div>
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default VerticalsCarouselOffer;
