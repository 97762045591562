import React, { useEffect, useState } from "react";
import MobileBanner from "../assets/images/home/homeMobile.png";
import Banner from "../assets/images/home/banner.png";
import SmallPizza from "../assets/images/home/Icon_1.png";
import HomeTopBG from "../assets/images/home/back_bg_top.png";
import HomeTopMobile from "../assets/images/home/section2_bg.png";
import HomeTopMobileElement from "../assets/images/home/HomeTopElement.png";
import Tomato from "../assets/images/home/Tomato_1.png";
import Leaf from "../assets/images/home/leaf_1.png";
import useRestaurantCategory from "../data/talons/RestaurantCategory/useRestaurantCategory";
import CarousalImage from "../assets/images/home/Image_1.png";
import Element from "../assets/images/home/element_1.png";
import VerticalsCarousel from "../components/VerticalsCarousel";
import { useSelector } from "react-redux";
import {
  getBranchMasterList,
  getOfferandPromotionList,
} from "../data/selectors/restaurantCategory.selector";
import VerticalsCarouselOffer from "../components/VerticalsCarouselOffer";

const HomeBanner = () => {
  const { fetchOfferPromotionsList } = useRestaurantCategory();
  const [isMobile, setIsMobile] = useState(false);
  const [topValue, setTopValue] = useState("top-18");
  useEffect(() => {
    fetchOfferPromotionsList({});

    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setIsMobile(true);
        setTopValue("top-30");
      } else {
        setIsMobile(false);
        setTopValue("top-20");
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const offerandPromotionList = useSelector(getOfferandPromotionList);


  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const isCurrentDateWithinRange = (fromDate, uptoDate) => {
    const currentDate = new Date();
    const start = new Date(fromDate);
    const end = new Date(uptoDate);
    return currentDate >= start && currentDate <= end;
  };

  const handleSlideChange = (currentSlideIndex) => {
    // console.log("Current Slide is:", currentSlideIndex);
  };

  // console.log(offerandPromotionList,'fetchOfferPromotionsList')

  useEffect(() => {
    if (isModalOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isModalOpen]);

  // console.log(offerandPromotionList, "okokok");

  return (
    <section className="h-full">
      <div
        className={`absolute ${topValue} left-0 w-full flex items-center justify-center bg-gradient-to-r from-green-400 to-blue-500 backdrop-blur-lg bg-opacity-30`}
      >
        <marquee
          className="w-full text-lg font-extrabold tracking-wide sm:text-xl md:text-2xl text-green max-w-screen"
          behavior="scroll"
          direction="left"
          scrollamount="6"
          onClick={toggleModal}
        >
          <button className="py-2 transition-transform transform rounded-lg shadow-md hover:scale-105 hover:text-yellow text-green">
          🎊 We’re  opening soon in Tumkur, Sompura &  Seegehalli !! Click Here to Stay connected. 🎊
          </button>
        </marquee>
      </div>
      <img
        src={Banner}
        alt="Banner"
        className="hidden object-contain w-full md:flex"
      />
      <img
        src={MobileBanner}
        alt="Banner"
        className="flex object-contain w-full md:hidden"
      />
      <div className="relative flex flex-col items-center justify-center w-full h-full px-4 pt-40 pb-16 -mt-12 text-center md:px-8 lg:px-20 xl:px-36 lg:-mt-28">
        <img
          src={HomeTopBG}
          alt="Background Image"
          className="absolute top-0 left-0 hidden w-full h-full -z-10 lg:flex"
        />
        <img
          src={HomeTopMobile}
          alt="Background Image"
          className="absolute top-0 left-0 flex w-full h-full -z-10 lg:hidden"
        />
        <img
          src={HomeTopMobileElement}
          alt="Background Element"
          className="absolute left-0 flex object-contain w-28 top-10 -z-10 lg:hidden"
        />
        <img
          src={SmallPizza}
          alt="Pizza Icon"
          className="object-contain w-32 mb-4 md:w-48"
        />
        <img
          src={Tomato}
          alt="Tomato Icon"
          className="absolute bottom-auto object-contain w-12 mt-8 lg:mt-0 md:w-20 xl:w-32 left-4 md:left-12 xl:left-8 2xl:left-40 lg:bottom-0 xl:bottom-4"
        />
        <img
          src={Leaf}
          alt="Leaf Icon"
          className="absolute object-contain w-16 md:w-24 xl:w-32 right-8 lg:right-16 2xl:right-36 top-40 lg:top-auto"
        />
        <p className="max-w-xs mb-8 text-base text-white font-description-medium xl:max-w-6xl md:text-lg md:max-w-none">
          Pizza is the heartthrob of fast food in India. But let's face it; the
          international big shots were hogging the spotlight, leaving us craving
          something more—something uniquely Indian, outrageously delicious, and
          damn affordable.
        </p>
        <p className="text-base text-yellow font-description-mediumitalic xl:max-w-6xl md:text-lg max-w-64 md:max-w-none">
          That's when The Fusion Pizza charged into the scene!
        </p>
        <p className="max-w-xs text-base text-white font-description-medium xl:max-w-6xl md:text-lg md:max-w-none">
          Started with two exceptional partners, and later an additional partner
          was brought on board to oversee and manage the brand's development.
        </p>
      </div>
      {isModalOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center px-4 overflow-y-auto transition-opacity duration-300 ease-in-out bg-black bg-opacity-70">
          <div className="bg-white p-4 sm:p-6 md:p-8 rounded-lg text-center shadow-lg max-w-6xl w-full max-h-[90vh] overflow-y-auto flex flex-col items-center">
            {/* <h1 className="mb-4 text-4xl font-semibold text-green-800 lg:text-5xl">
              We are{" "}
              {offerandPromotionList.length > 0
                ? offerandPromotionList[0].status_val
                : "COMING SOON..."}
            </h1>
            <p className="mb-6 text-lg font-normal text-green-700">
              Currently, we are at 12 locations, with plans to expand to more
              key locations across Gujarat and India.
            </p> */}
            {/* Carousel Section */}
            <div className="flex flex-col items-center justify-center w-full gap-4 mb-4 lg:flex-row lg:gap-6">
              <VerticalsCarouselOffer
                data={offerandPromotionList.map((item) => ({
                  branch_name: item.title || "No Title",
                  address: item.details || "No Details Available",
                  branch_location_link: item.location_url || "#",
                  currentStatus: item.status_val || "Coming soon",
                  image_url: item.photo_url,
                  from_date: item.from_date,
                  upto_date: item.upto_date,
                }))}
                onSlideChange={handleSlideChange}
              />
            </div>

            {/* Close Button */}
            <button
              className="bg-yellow text-white text-sm sm:text-lg px-4 sm:px-6 py-2 sm:py-3 rounded-lg hover:bg-yellow-600 transition-colors duration-200 ease-in-out w-full max-w-[200px]"
              onClick={toggleModal}
            >
              Close
            </button>
          </div>
        </div>
      )}
    </section>
  );
};

export default HomeBanner;
