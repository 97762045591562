import React, { useCallback, useEffect, useState } from 'react';
import Banner from '../assets/images/franchise/franchise_bg_mid.png';
import BannerMobile from '../assets/images/franchise/back_mid_mobile.png';
import GreenLines from '../assets/images/franchise/green_lines.png';
import Pizza from '../assets/images/franchise/pizza_1.png';
import Icon1 from '../assets/images/franchise/big_icon_1.png';
import Icon2 from '../assets/images/franchise/big_icon_2.png';
import Icon3 from '../assets/images/franchise/big_icon_3.png';
import Icon4 from '../assets/images/franchise/big_icon_4.png';
import Icon5 from '../assets/images/franchise/big_icon_5.png';
import Icon6 from '../assets/images/franchise/big_icon_6.png';
import Icon7 from '../assets/images/franchise/big_icon_7.png';
import Icon8 from '../assets/images/franchise/big_icon_8.png';
import Icon9 from '../assets/images/franchise/big_icon_9.png';
import Icon10 from '../assets/images/franchise/big_icon_10.png';
import Tomato from '../assets/images/franchise/tomato_1.png';
import Leaf1 from '../assets/images/franchise/leaf_1.png';
import Leaf2 from '../assets/images/home/leaf_2.png';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { joiResolver } from '@hookform/resolvers/joi';
import useRestaurantCategory from '../data/talons/RestaurantCategory/useRestaurantCategory';
import { resetInquiryFormData } from '../data/reducers/restaurantCategory.reducer';

const InquiryAndServices = () => {
  const dispatch = useDispatch();
  const { InquiryEntry, inquiryEntrySchema, setAlert, alert } =
    useRestaurantCategory();

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    reset,
    setValue,
  } = useForm({
    resolver: joiResolver(inquiryEntrySchema),
    defaultValues: {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      cityPreference: "",
      openStoreTimeline: "",
      budget: "",
      ownedBusiness: "",
      franchisePartner: "",
      commercialSpace: "",
      brandName: "",
      aboutYourself: "",
    },
  });
  
  useEffect(() => {
    const timer = setTimeout(() => {
      setAlert(false);
    }, 3000);

    // To clear or cancel a timer, you call the clearTimeout(); method,
    // passing in the timer object that you created into clearTimeout().

    return () => clearTimeout(timer);
  }, [alert]);
  const onUserSubmit = useCallback(async () => {
    try {
      const res = await InquiryEntry({
        ...getValues(),
      });
      if (res == true) {
        setAlert(true);
        reset();
        dispatch(resetInquiryFormData());
        setValue('');
      }
    } catch (err) {
      console.log('errr', err);
    }
  }, [InquiryEntry]);

  const onlyNumbers = (e) => {
    e.target.value = e.target.value.replace(/[^0-9]/g, '');

    if (e.target.value.toString().length === 0) return;
    return (e.target.value = Math.max(0, parseInt(e.target.value))
      .toString()
      .slice(0, 10));
  };
  return (
    <section className="relative z-10 flex -mt-12 lg:px-20 xl:px-36">
      <img
        src={Banner}
        alt="Background Image"
        className="absolute top-0 left-0 hidden w-full h-full -z-10 lg:flex"
      />
      <img
        src={BannerMobile}
        alt="Background Image"
        className="absolute top-0 left-0 flex object-cover object-left-top w-full h-full -z-10 lg:hidden"
      />
      <img
        src={Tomato}
        alt="Tomato"
        className="absolute hidden object-contain w-24 xl:w-32 right-24 xl:right-48 top-96 xl:top-1/4 lg:flex"
      />
      <img
        src={Leaf1}
        alt="Leaf"
        className="absolute hidden object-contain w-32 left-32 xl:left-60 top-1/4 xl:top-1/3 lg:flex"
      />
      <img
        src={Leaf2}
        alt="Leaf"
        className="absolute hidden object-contain w-40 -bottom-8 right-44 lg:flex"
      />
      <div className="relative flex flex-col">
        {/* <div className="flex flex-col w-full -mt-36">
          <h2 className="self-center px-8 pt-2 text-2xl text-center bg-yellow rounded-t-2xl md:rounded-t-3xl md:pt-4 md:px-20 text-dark-green font-title md:text-4xl">
            INQUIRY FORM
          </h2>
          <form className="flex flex-col w-full gap-8 px-12 py-12 -mt-1 bg-yellow md:px-20 rounded-2xl md:rounded-3xl">
            <div className="flex flex-wrap w-full gap-8 md:flex-nowrap">
              <input
                type="text"
                placeholder="First Name"
                className="w-full px-4 py-2 rounded-lg"
                {...register('name')}
                autoComplete="false"
              />
              <input
                type="email"
                placeholder="Your E-mail ID"
                className="w-full px-4 py-2 rounded-lg"
                {...register('email_id')}
                autoComplete="false"
              />
            </div>
            <div className="flex flex-wrap w-full gap-8 md:flex-nowrap">
              <input
                type="tel"
                onInput={(e) => onlyNumbers(e)}
                placeholder="Mobile Number"
                {...register('contact_num')}
                autoComplete="false"
                className="w-full px-4 py-2 rounded-lg"
              />
              <input
                type="text"
                placeholder="Your City"
                className="w-full px-4 py-2 rounded-lg"
                {...register('city')}
                autoComplete="false"
              />
            </div>
            <button
              onClick={(e) => {
                e.preventDefault();
                handleSubmit(onUserSubmit());
              }}
              className="self-center px-4 py-2 text-base text-white uppercase rounded-lg font-description-bold bg-dark-green md:px-12 md:text-xl"
            >
              Submit Form
            </button>
            {alert === true ? (
              <div className="text-center text-white ease-in">
                Thank You For Showing Interest!
              </div>
            ) : null}
          </form>
          <img src={Pizza} alt="Pizza" />
        </div> */}
        <div className="flex flex-col w-full -mt-36">
  <h2 className="self-center px-8 pt-2 text-2xl text-center bg-yellow rounded-t-2xl md:rounded-t-3xl md:pt-4 md:px-20 text-dark-green font-title md:text-4xl">
    INQUIRY FORM
  </h2>
  <form className="flex flex-col w-full gap-8 px-12 py-12 -mt-1 bg-yellow md:px-20 rounded-2xl md:rounded-3xl">
    <div className="flex flex-wrap w-full gap-8 md:flex-nowrap">
    <div className="flex flex-col w-full">
                <label htmlFor="firstName">First Name</label>
                <input
                  type="text"
                  className="w-full px-4 py-2 rounded-lg"
                  id="firstName"
                  {...register('firstName')}
                />
                {errors.firstName && (
                  <span className="text-red-500">First name is required</span>
                )}
              </div>
              <div className="flex flex-col w-full">
                <label htmlFor="lastName">Last Name</label>
                <input
                  type="text"
                  className="w-full px-4 py-2 rounded-lg"
                  id="lastName"
                  {...register('lastName')}
                />
                {errors.lastName && (
                  <span className="text-red-500">Last name is required</span>
                )}
              </div>
    </div>

    <div className="flex flex-wrap w-full gap-8 md:flex-nowrap">
    <div className="flex flex-col w-full">
                <label htmlFor="email">Email</label>
                <input
                  type="email"
                  className="w-full px-4 py-2 rounded-lg"
                  id="email"
                  {...register('email')}
                />
                {errors.email && (
                  <span className="text-red-500">Valid email is required</span>
                )}
              </div>
              <div className="flex flex-col w-full">
                <label htmlFor="phone">Phone</label>
                <input
                  type="tel"
                  onInput={onlyNumbers}
                  className="w-full px-4 py-2 rounded-lg"
                  id="phone"
                  {...register('phone')}
                />
                {errors.phone && (
                  <span className="text-red-500">Valid phone number is required</span>
                )}
              </div>
    </div>

    <div className="flex flex-wrap w-full gap-8 md:flex-nowrap">
    <div className="flex flex-col w-full">
                <label htmlFor="cityPreference">City Preference</label>
                <input
                  type="text"
                  className="w-full px-4 py-2 rounded-lg"
                  id="cityPreference"
                  {...register('cityPreference')}
                />
                {errors.cityPreference && (
                  <span className="text-red-500">City preference is required</span>
                )}
              </div>
              <div className="flex flex-col w-full">
                <label htmlFor="openStoreTimeline">Open Store Timeline</label>
                <input
                  type="text"
                  className="w-full px-4 py-2 rounded-lg"
                  id="openStoreTimeline"
                  {...register('openStoreTimeline')}
                />
                {errors.openStoreTimeline && (
                  <span className="text-red-500">This field is required</span>
                )}
              </div>
    </div>

    {/* First and third radio button questions on the same line */}
    <div className="flex flex-wrap w-full gap-8 md:flex-nowrap">
    <div className="flex flex-col w-full">
                <label>What is your budget slab?</label>
                <div className="flex gap-4">
                  <label>
                    <input type="radio" value="1" {...register('budget')} />
                    25-30 lakhs
                  </label>
                  <label>
                    <input type="radio" value="2" {...register('budget')} />
                    30-35 lakhs
                  </label>
                  <label>
                    <input type="radio" value="3" {...register('budget')} />
                    35+ lakhs
                  </label>
                </div>
                {errors.budget && (
                  <span className="text-red-500">Budget selection is required</span>
                )}
              </div>

              <div className="flex flex-col w-full">
                <label>Do you own any commercial space?</label>
                <div className="flex gap-4">
                  <label>
                    <input type="radio" value="1" {...register('commercialSpace')} />
                    Yes
                  </label>
                  <label>
                    <input type="radio" value="2" {...register('commercialSpace')} />
                    No
                  </label>
                </div>
                {errors.commercialSpace && (
                  <span className="text-red-500">This selection is required</span>
                )}
              </div>
    </div>

    {/* Second and fourth radio button questions on the same line */}
    <div className="flex flex-wrap w-full gap-8 md:flex-nowrap">
      <div className="flex flex-col w-full">
        <label>Have you ever owned a business?</label>
        <div className="flex gap-4">
          <label>
            <input type="radio" value={1} {...register('ownedBusiness')} />
            Yes
          </label>
          <label>
            <input type="radio" value={2} {...register('ownedBusiness')} />
            No
          </label>
        </div>
        {errors.ownedBusiness && (
                  <span className="text-red-500">This selection is required</span>
                )}
      </div>

      <div className="flex flex-col w-full">
        <label>Have you ever been a Franchise Partner of any entity?</label>
        <div className="flex gap-4">
          <label>
            <input type="radio" value={1} {...register('franchisePartner')} />
            Yes
          </label>
          <label>
            <input type="radio" value={2} {...register('franchisePartner')} />
            No
          </label>
        </div>
        {errors.franchisePartner && (
                  <span className="text-red-500">This selection is required</span>
                )}
      </div>
    </div>

    <div className="flex flex-col gap-4">
      <label>Which Brand?</label>
      <input
        type="text"
        className="w-full px-4 py-2 rounded-lg"
        {...register('brandName')}
      />
    </div>

    <div className="flex flex-col gap-4">
      <label>Tell us about yourself...</label>
      <textarea
        className="w-full px-4 py-2 rounded-lg"
        {...register('aboutYourself')}
      />
    </div>

    <button
      onClick={(e) => {
        e.preventDefault();
        handleSubmit(onUserSubmit)();
      }}
      className="self-center px-4 py-2 text-base text-white uppercase rounded-lg font-description-bold bg-dark-green md:px-12 md:text-xl"
    >
      Submit Form
    </button>

    {alert === true ? (
      <div className="text-center text-white ease-in">
        Thank You For Showing Interest!
      </div>
    ) : null}
  </form>
  <img src={Pizza} alt="Pizza" />
</div>

        <div className="flex flex-col items-center justify-center gap-8 pt-12 pb-36">
          <h3 className="text-3xl text-center font-title text-green md:text-4xl lg:text-5xl">
            Supportive services
          </h3>
          <div className="flex flex-wrap items-center justify-center max-w-lg gap-2 md:gap-12 lg:max-w-5xl">
            <div className="flex flex-col items-center justify-center w-32 gap-4 md:w-48">
              <img
                src={Icon1}
                alt="Icon 1"
                className="object-contain h-20 md:h-32"
              />
              <span className="text-sm text-center font-description-semibold md:text-base">
                POS Billlng & <br /> Separate Application <br /> Panel
              </span>
            </div>
            <div className="flex flex-col items-center justify-center w-32 gap-4 md:w-48">
              <img
                src={Icon2}
                alt="Icon 2"
                className="object-contain h-20 md:h-32"
              />
              <span className="text-sm text-center font-description-semibold md:text-base">
                SMS and <br /> Whatsapp Marketing <br /> Support
              </span>
            </div>
            <div className="flex flex-col items-center justify-center w-32 gap-4 md:w-48">
              <img
                src={Icon3}
                alt="Icon 3"
                className="object-contain h-20 md:h-32"
              />
              <span className="text-sm text-center font-description-semibold md:text-base">
                Registration <br /> Support with Online <br /> Delivery Channels
              </span>
            </div>
            <div className="flex flex-col items-center justify-center w-32 gap-4 md:w-48">
              <img
                src={Icon4}
                alt="Icon 4"
                className="object-contain h-20 md:h-32"
              />
              <span className="text-sm text-center font-description-semibold md:text-base">
                Raw Material <br /> Setup & Kitchen Staff <br /> Training
                Support
              </span>
            </div>
            <div className="flex flex-col items-center justify-center w-32 gap-4 md:w-48">
              <img
                src={Icon5}
                alt="Icon 5"
                className="object-contain h-20 md:h-32"
              />
              <span className="text-sm text-center font-description-semibold md:text-base">
                Marketing Guidelines <br /> & Sales grow up <br /> Guidelines
              </span>
            </div>
            <div className="flex flex-col items-center justify-center w-32 gap-4 md:w-48">
              <img
                src={Icon6}
                alt="Icon 6"
                className="object-contain h-20 md:h-32"
              />
              <span className="text-sm text-center font-description-semibold md:text-base">
                Regular <br /> visit of company <br /> employees
              </span>
            </div>
            <div className="flex flex-col items-center justify-center w-32 gap-4 md:w-48">
              <img
                src={Icon7}
                alt="Icon 7"
                className="object-contain h-20 md:h-32"
              />
              <span className="text-sm text-center font-description-semibold md:text-base">
                Grand Opening <br /> Standard layout given <br /> by Company
              </span>
            </div>
            <div className="flex flex-col items-center justify-center w-32 gap-4 md:w-48">
              <img
                src={Icon8}
                alt="Icon 8"
                className="object-contain h-20 md:h-32"
              />
              <span className="text-sm text-center font-description-semibold md:text-base">
                Event Planning <br /> Guidelines <br /> Support
              </span>
            </div>
            <div className="flex flex-col items-center justify-center w-32 gap-4 md:w-48">
              <img
                src={Icon9}
                alt="Icon 9"
                className="object-contain h-20 md:h-32"
              />
              <span className="text-sm text-center font-description-semibold md:text-base">
                Regular Food <br /> Innovations
              </span>
            </div>
            <div className="flex flex-col items-center justify-center w-32 gap-4 md:w-48">
              <img
                src={Icon10}
                alt="Icon 10"
                className="object-contain h-20 md:h-32"
              />
              <span className="text-sm text-center font-description-semibold md:text-base">
                Social Media <br /> Support
              </span>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default InquiryAndServices;