export const getRestaurantCategoryList = (store) =>
  store.restaurantCategory.filters.restaurantCategory;

export const getRestaurantProductList = (store) =>
  store.restaurantCategory.filters.restaurantProduct;

export const getBranchMasterList = (store) =>
  store.restaurantCategory.filters.branchMasterList;

export const getInquiryList = (store) =>
  store.restaurantCategory.filters.inquiryList;

export const getOfferandPromotionList = (store) =>
  store.restaurantCategory.filters.offerandPromotionList;

export const getBranchId = (store) => store.restaurantCategory.filters.branchId;
