import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useApi } from "../apiCall/useApi";
import { Urls } from "../../../utils/constant";
import Joi from "joi";
import {
  setRestaurantCategoryList,
  setRestaurantProductList,
  setBranchMasterList,
  setInquiryList,
  setBranchId,
  setOfferandPromotionList,
} from "../../reducers/restaurantCategory.reducer";

import {
  getBranchMasterList,
  getBranchId,
} from "../../selectors/restaurantCategory.selector";
import { size } from "lodash";

const inquiryEntrySchema = Joi.object({
  firstName: Joi.string().min(2).max(50).required().label("First Name"),
  lastName: Joi.string().min(2).max(50).required().label("Last Name"),
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .required()
    .label("Email"),
  phone: Joi.string()
    .pattern(/^\d{10}$/)
    .required()
    .label("Phone")
    .messages({
      "string.pattern.base": "Phone number must have 10 digits.",
    }),
  cityPreference: Joi.string()
    .min(2)
    .max(100)
    .required()
    .label("City Preference"),
  openStoreTimeline: Joi.string()
    .min(2)
    .max(100)
    .required()
    .label("Open Store Timeline"),
  budget: Joi.number().valid(1, 2, 3).required().label("Budget"),
  commercialSpace: Joi.number()
    .valid(1, 2)
    .required()
    .label("Commercial Space"),
  ownedBusiness: Joi.number().valid(1, 2).required().label("Owned Business"),
  franchisePartner: Joi.number()
    .valid(1, 2)
    .required()
    .label("Franchise Partner"),
  brandName: Joi.string().allow("").optional().label("Brand Name"),
  aboutYourself: Joi.string().allow("").optional().label("About Yourself"),
});
const menu_list = [
  { id: 0, label: "Our Menu" },
  { id: 1, label: "Veg Menu" },
  { id: 2, label: "Veg & Non Veg" },
];

export default function useRestaurantCategory() {
  const dispatch = useDispatch();
  const { apiPost } = useApi();
  const branchMasterList = useSelector(getBranchMasterList);
  const branchId = useSelector(getBranchId);
  const [loading, setLoading] = useState(false);
  const [menuId, setMenuId] = useState(null);

  // const webUserId = useSelector(getWebUserId);
  const [InquiryId, setinquiryId] = useState(null);
  // const [name, setName] = useState(null);
  // const [first_name, setFirstName] = useState(null);
  // const [last_name, setLastName] = useState(null);
  // const [email_id, setEmail] = useState(null);
  const [categoryId, setCategoryId] = useState(null);
  const [menuURL, setMenuURL] = useState("");
  const [dataText, setDataText] = useState(0);
  const [count, setCount] = useState(0);
  const [alert, setAlert] = useState(false);

  const InquiryEntry = useCallback(
    async ({
      firstName,
      lastName,
      phone,
      cityPreference,
      email,
      openStoreTimeline,
      budget,
      ownedBusiness,
      franchisePartner,
      commercialSpace,
      brandName,
      aboutYourself,
    }) => {
      if (loading) return;
      setLoading(true);
      try {
        const { data } = await apiPost({
          url: Urls.inquiryAdd(),
          data: {
            first_name: firstName,
            last_name: lastName,
            email_id: email,
            contact_num: phone,
            city_preference: cityPreference,
            open_store: openStoreTimeline,
            budget_slabs: budget,
            owned_a_business: ownedBusiness,
            partner_entity: franchisePartner,
            commercial_space: commercialSpace,
            which_brand: brandName,
            message: aboutYourself,
            status: 1,
            inquiry_status: "1d47788b-51b3-4f25-b9af-c3c63f372a7a",
          },
        });

        dispatch(setInquiryList(data.id));
        let id = data.id;
        if (size(id) === 36) {
          return true;
        }
      } catch (err) {
        // hideLoader();
        setLoading(false);
        console.log(err);
        return false;
      }
    },
    [loading]
  );

  const ContactUsInquiryEntry = useCallback(
    async ({ name, contact_num, message, email_id }) => {
      if (loading) return;
      setLoading(true);
      try {
        //showLoader();

        const { data } = await apiPost({
          url: Urls.inquiryAdd(),
          data: {
            name: name,
            email_id: email_id,
            contact_num: contact_num,
            message: message,
          },
        });
        dispatch(setInquiryList(data.id));
        let id = data.id;
        if (size(id) === 36) {
          return true;
        }
      } catch (err) {
        // hideLoader();
        setLoading(false);
        console.log(err);
        return false;
      }
    },
    [loading]
  );

  const fetchRestaurantProductList = useCallback(
    async ({ categoryId, branchId }) => {
      try {
        const { data } = await apiPost({
          url: Urls.getRestaurantProduct(),
          data: {
            category_id: categoryId,
            branch_id: branchId,
            page_size: 15,
            current_page: 1,
            name: "",
          },
        });
        dispatch(setRestaurantProductList(data.data));
      } catch (err) {
        console.log(err);
      }
    },
    [apiPost, dispatch]
  );

  const fetchRestaurantCategoryList = useCallback(
    async ({ branchId }) => {
      try {
        const { data } = await apiPost({
          url: Urls.getRestaurantCategoryCombo(),
          data: { branch_id: branchId },
        });

        dispatch(setRestaurantCategoryList(data.data));

        if (data.data.length > 0) {
          const newCategoryId = data.data[0].id;
          setCategoryId(newCategoryId); // Update category state
          await fetchRestaurantProductList({
            categoryId: newCategoryId,
            branchId,
          });
        } else {
          setCategoryId(""); // Reset category if no categories found
          dispatch(setRestaurantProductList([])); // Clear products
        }
      } catch (err) {
        console.log(err);
      }
    },
    [apiPost, dispatch, fetchRestaurantProductList]
  );

  const fetchBranchMasterList = useCallback(async () => {
    try {
      const { data } = await apiPost({
        url: Urls.getBranchMaster(),
        data: {
          branch_name: "",
          status: 1,
          current_page: 1,
          page_size: 20,
        },
      });

      dispatch(setBranchMasterList(data?.data || []));

      if (data.data.length > 0) {
        const newBranchId = data.data[0].id;
        dispatch(setBranchId(newBranchId));
        setMenuURL(data.data[0].document_path);

        await fetchRestaurantCategoryList({ branchId: newBranchId });
      }
    } catch (err) {
      console?.log(err);
    }
  }, [apiPost, dispatch, fetchRestaurantCategoryList]);

  const fetchOfferPromotionsList = useCallback(async () => {
    try {
      const { data } = await apiPost({
        url: Urls.getOfferAndPromotion(),
        data: { status: 0, page_size: 15, current_page: 1 },
      });
      dispatch(setOfferandPromotionList(data?.data || []));
      // setting list to reducer
    } catch (err) {
      console?.log(err);
    }
  }, [apiPost, dispatch]);

  const InquiryEntryHome = useCallback(
    async ({ first_name, last_name, contact_num, email_id }) => {
      if (loading) return;
      setLoading(true);
      try {
        //showLoader();
        const { data } = await apiPost({
          url: Urls.inquiryAdd(),
          data: {
            name: first_name + last_name,
            email_id: email_id,
            contact_num: contact_num,
          },
        });
        dispatch(setInquiryList(data.id));
        let id = data.id;
        if (size(id) === 36) {
          return true;
        }
        setLoading(false);
        // hideLoader();
      } catch (err) {
        // hideLoader();
        setLoading(false);
        console.log(err);
        return false;
      }
    },
    [apiPost, dispatch, loading]
  );

  const handleRestaurantCategoryNameChange = useCallback(async (e) => {
    setCategoryId(e.target.value);
    // await fetchRestaurantProductList({
    //   categoryId: e.target.value,
    //   branchId: branchId,
    // });
  }, []);

  const handleBranchMasterComboChange = useCallback(
    async (e) => {
      dispatch(setBranchId(e.target.value));
      const object = branchMasterList.find((obj) => obj.id === e.target.value);
      setMenuURL(object.document_path);
      // setCategoryId(null);
      // await fetchRestaurantCategoryList({ branchId: e.target.value });

      // if (size(categoryId) >= 0) {
      //   await fetchRestaurantProductxList({ categoryId: categoryId, branchId: e.target.value });
      // }
    },
    [branchMasterList, dispatch]
  );

  const handleLeftClick = () => {
    if (count === 0) {
      let max = branchMasterList.length - 1;
      setCount(max);
      setDataText(max);
    } else if (count > 0) {
      setCount((count) => count - 1);
      setDataText((count) => count - 1);
    }
  };

  const handleRightClick = () => {
    if (count === branchMasterList.length - 1) {
      setCount(0);
      setDataText(0);
    } else {
      setCount((count) => count + 1);
      setDataText((count) => count + 1);
    }
  };

  const handleMenuChange = useCallback(
    (e) => {
      setMenuId(e.target.value);
    },
    [menuId]
  );

  const formLoadEvent = useCallback(
    async (e) => {
      await fetchBranchMasterList();
      setCategoryId(null);
    },
    [fetchBranchMasterList]
  );

  return {
    formLoadEvent,
    InquiryEntry,
    InquiryEntryHome,
    fetchRestaurantCategoryList,
    fetchRestaurantProductList,
    handleRestaurantCategoryNameChange,
    handleBranchMasterComboChange,
    fetchBranchMasterList,
    handleLeftClick,
    handleRightClick,
    ContactUsInquiryEntry,
    setAlert,
    handleMenuChange,
    fetchOfferPromotionsList,
    setCategoryId,
    inquiryEntrySchema,
    setRestaurantCategoryList,
    categoryId,
    count,
    dataText,
    alert,
    menu_list,
    menuId,
    menuURL,
  };
}
