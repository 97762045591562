import { useCallback } from "react";
import Api from "../../../utils/api.utils";
import { useSelector } from "react-redux";
import { getTokenId } from "../../selectors/appState.selector";
import { size } from "lodash";

export function useApi() {
  // const tokenId =
  //   "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoiNjE2QzA0MEYtQTAzRi00OTAzLTkzOUMtMjUwMEE5MzYxMDdDIiwiaWF0IjoxNzI0MzIwMDgxLCJleHAiOjE3MjY5MTIwODF9.-1NWpcRfkgE84Llvx6rUsEl84_EDmbGWfFYq6Fzx-pk";

  // CRM API Token
  // const tokenId =
  //   "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoiOUJDOEQwRjEtMTdCMi00Rjk1LUI5ODItRUEyNTczRUM3NUJDIiwiZGJfbmFtZSI6IkNSTV8wMDAwMiIsImlhdCI6MTcxODAyNTk2NCwiZXhwIjoxNzQ5NTYxOTY0fQ.7UPr3oQJ_KKHb9Rk9VxBAw95UdY25SVzXvP0N44k_6U";

  // PRASHI API Token
  const tokenId =
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoiMjkxMEQxMzctN0QyMC00RUU5LUFGN0MtNTg4RDQyM0U0OENCIiwiZGJfbmFtZSI6IlBBXzAwMDAxIiwiaWF0IjoxNzM4MDUyNjA5LCJleHAiOjE3Njk1ODg2MDl9.D7d9FZ6PTMNJQfyMq2464wEDQ8hYpNRYcVJATpVA9jo";

  const apiPost = useCallback(
    async (config) => {
      let mResponse = [];
      try {
        if (size(tokenId) === 0) {
          mResponse = await Api.post({
            ...config,
            header: "",
          });
        } else {
          mResponse = await Api.post({
            ...config,
            header: `Bearer ` + tokenId,
          });
        }
      } catch (err) {
        console.log("Post Error :- " + err);
      } finally {
        return mResponse;
      }
    },
    [tokenId]
  );

  return {
    apiPost,
  };
}
